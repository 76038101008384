import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { quizActions } from "_store";
import { useParams } from "react-router-dom";
import axios from "axios";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";
import { Table } from "react-bootstrap";
import { Modal, Button } from "react-bootstrap";

export { StudyForVocabularyWritingEng2Eng };

function StudyForVocabularyWritingEng2Eng() {
  const dispatch = useDispatch();
  const { program_id, type, q_start, chunk_id } = useParams();
  const { quiz } = useSelector((x) => x.quiz);

  const { passage } = useSelector((x) => x.passage);
  if (type == "Listening-EngW2EngM") {
    var sentences =
      quiz && quiz.data
        ? quiz.data.map((word) => ({
            question: word.word,
            answer: word.meaning,
            audio: word.audio_file_link,
          }))
        : [];
  } else if (type == "Listening-EngM2EngW") {
    var sentences =
      quiz && quiz.data
        ? quiz.data.map((word) => ({
            question: word.word,
            answer: word.word,
            audio: word.english_meaning_audio,
          }))
        : [];
  } else if (type == "Eng1") {
    var sentences =
      quiz && quiz.data
        ? quiz.data.map((word) => ({
            question: word.word,
            answer: word.meaning,
          }))
        : [];
  } else {
    var sentences =
      quiz && quiz.data
        ? quiz.data.map((word) => ({
            question: word.meaning,
            answer: word.word,
          }))
        : [];
  }

  // const sentences = (quiz && quiz.data) ? quiz.data.map(word => ({ question: word.answer,
  //   answer: word.word, categorytype: word.categorytype })) : [];

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentAnswer, setCurrentAnswer] = useState("");
  const [userAnswers, setUserAnswers] = useState([]);
  const [score, setScore] = useState(0);
  const [answeredQuestions, setAnsweredQuestions] = useState(0);
  const [timer, setTimer] = useState(0);
  const [showCorrectAnswer, setShowCorrectAnswer] = useState(false);
  const [isLastQuestion, setIsLastQuestion] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [time, setTime] = useState(0);
  const [showResult, setShowResult] = useState(false);
  const student = JSON.parse(localStorage.getItem("user"));
  const [inactive, setInactive] = useState(false);
  const intervalRef = useRef();
  const [correct, setCorrectAnswer] = useState("");
  const [resultRecordId, setResultRecordId] = useState();
  const [accuracyPercent, setAccuracyPercent] = useState(0);
  const [correctCount, setCorrectAnswerCount] = useState(0);
  const [categoryTypeAnswers, setCategoryTypeAnswers] = useState([]);
  const [incorrectAnswer, setInCorrectAnswer] = useState(0);
  const [audioSrc, setAudio] = useState();
  const [pendingSuggestion, setPendingSuggestion] = useState("");
  const [blinkingWord, setBlinkingWord] = useState("");
  const totalSentences = 5; //(sentences) ? sentences.length : 0;
  const [visibleHearts, setVisibleHearts] = useState();
  const [clickedHearts, setClickedHearts] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState("");
  const [raiseFlag, setRaiseFlag] = useState(false);
  
  const audioRef = useRef();

  useEffect(() => {
    const total = 5; //(sentences) ? sentences.length : 0;
    setVisibleHearts(Array(total).fill(true));
  }, [sentences, currentQuestionIndex]);
  useEffect(() => {
    let timer;

    const resetTimer = () => {
      clearTimeout(timer);
      // Start the timer again
      timer = setTimeout(() => {
        setInactive(true);
        // Pause the stopwatch
        clearInterval(intervalRef.current);
        setIsRunning(false);
      }, 120000); // 2 minutes (120000 milliseconds)
    };

    const handleActivity = () => {
      if (inactive) {
        setInactive(false);
        // Resume the stopwatch
        start();
      }
      resetTimer();
    };

    // Add event listeners to monitor user activity
    window.addEventListener("keydown", handleActivity);
    window.addEventListener("click", handleActivity);

    // Start the initial timer
    resetTimer();

    // Clean up event listeners
    return () => {
      window.removeEventListener("keydown", handleActivity);
      window.removeEventListener("click", handleActivity);
      clearTimeout(timer);
    };
  }, [inactive]);
  useEffect(() => {
    if (sentences.length > 0) {
      if (type == "Listening-EngW2EngM") {
        setAudio(sentences[currentQuestionIndex].audio);
      } else if (type == "Listening-EngM2EngW") {
        setAudio(sentences[currentQuestionIndex].audio);
      }
    }
  }, [sentences]);
  // Format the time values to ensure they have leading zeros
  const formatTime = (time) => {
    const hours = Math.floor(time / 3600000); // 1 hour = 3600000 milliseconds
    const minutes = Math.floor((time % 3600000) / 60000); // 1 minute = 60000 milliseconds
    const seconds = Math.floor((time % 60000) / 1000); // 1 second = 1000 milliseconds
    const milliseconds = Math.floor((time % 1000) / 10);

    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
      milliseconds: String(milliseconds).padStart(2, "0"),
    };
  };
  useEffect(() => {
    start(); // Start the stopwatch when the component mounts
    // Clean-up function to clear the interval when the component unmounts
    return () => clearInterval(intervalRef.current);
  }, []); // Empty dependency array ensures this effect runs only once

  const start = () => {
    if (!isRunning) {
      setIsRunning(true);
      const currentTime = Date.now();
      const timeElapsed = currentTime - time;
      intervalRef.current = setInterval(() => {
        setTime((prevTime) => prevTime + 1000); // Increment by 1 second (1000 milliseconds)
      }, 1000);
    }
  };
  const { hours, minutes, seconds, milliseconds } = formatTime(time);

  // useEffect(() => {
  //   setIsLastQuestion(currentQuestionIndex === sentences.length - 1);
  // }, [currentQuestionIndex, sentences.length]);

  const handleAnswerSubmit = () => {
    setClickedHearts([]);
    const correctAnswer = sentences[currentQuestionIndex].answer;
    const correctQuestion = sentences[currentQuestionIndex].question;
    console.log(correctQuestion,'get correct');
    setCurrentQuestion(correctQuestion);
    setCorrectAnswer(correctAnswer);
    // Split both correct answer and user answer into words
    const correctWords = correctAnswer
      .toLowerCase()
      .replace(/[^\w\s]/g, "")
      .split(/\s+/);
    const userWords = currentAnswer
      .toLowerCase()
      .replace(/[^\w\s]/g, "")
      .split(/\s+/);
    if (type == "Listening-EngW2EngM" || type == "Listening-EngM2EngW") {
      setAudio(sentences[currentQuestionIndex].audio);
    }
    // Count the number of matching words
    let matchingWords = 0;
    userWords.forEach((userWord) => {
      if (correctWords.includes(userWord)) {
        matchingWords++;
      }
    });
    console.log(matchingWords, "get macting ",correctWords.length);
    // Calculate percentage of correct words
    if (correctWords.length === matchingWords) {
      var percentage = 100;
    } else {
      var percentage = (matchingWords / userWords.length) * 100;
    }
    setAccuracyPercent(percentage);

      //console.log(percentage,'get percentage');return false;
    if (!currentAnswer.trim()) {
      alert("Please write an answer.");
      return;
    }
    const isCorrect =
      currentAnswer.toLowerCase() === correctAnswer.toLowerCase();
    // Update userAnswers state
    var updatedUserAnswerlist = [
      userAnswers,
      {
        question: sentences[currentQuestionIndex].question,
        correctAnswer: sentences[currentQuestionIndex].answer,
        userAnswer: currentAnswer,
        isCorrect,
      },
    ];
    setUserAnswers((prevUserAnswers) => {
      var updatedUserAnswers = [
        ...prevUserAnswers,
        {
          question: sentences[currentQuestionIndex].question,
          correctAnswer: sentences[currentQuestionIndex].answer,
          userAnswer: currentAnswer,
          isCorrect,
        },
      ];
      return updatedUserAnswers;
    });
    // Call handleSubmit after updating userAnswers state

    if (isCorrect) {
      if (score > 100) {
        var sum = 100;
        setScore(100);
      } else {
        var sum = parseInt(score) + 10;
        setScore(sum);
        console.log(sum, "get my score123", isCorrect);
      }

      setCorrectAnswerCount(correctCount + 1);
      setCategoryTypeAnswers({
        [sentences[currentQuestionIndex].categorytype]: {
          correct: correctCount + 1,
          incorrect: incorrectAnswer,
        },
      });
      setShowCorrectAnswer(false);
      setCurrentAnswer("");
    } else {
      if (score >= 1) {
        var sum = parseInt(score) - 1;
        setScore(sum);
      }
      setInCorrectAnswer(incorrectAnswer + 1);
      setCategoryTypeAnswers({
        [sentences[currentQuestionIndex].categorytype]: {
          correct: correctCount,
          incorrect: incorrectAnswer + 1,
        },
      });
      setShowCorrectAnswer(true);
    }
    setAnsweredQuestions((prevCount) => prevCount + 1);
    // setCurrentAnswer('');
    if (currentQuestionIndex === sentences.length - 1) {
      setCurrentQuestionIndex(0);
      setIsLastQuestion(false);
      //setIsLastQuestion(true);
    } else {
      //setShowCorrectAnswer(false);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
    const currentTime = time;
    // Stop the stopwatch by clearing the interval
    clearInterval(intervalRef.current);
    // Stop the stopwatch
    setIsRunning(false);
    // Set the time to the current time so that the stopwatch resumes from where it left off
    setTime(currentTime);
    console.log(score, "get my score", isCorrect, updatedUserAnswerlist);
    handleSubmit(sum, updatedUserAnswerlist);
  };

  const handleSubmit = async (sum, updatedUserAnswerlist) => {
    // Make an API call to submit data here
    //console.log(sum,'get s');return false;
    var q_end = parseInt(q_start) + 9;
    if (type == "Listening-EngW2EngM") {
      var subtype =
        "Vocabulary Listening Writing: Listen and write the correct meaning (English word to English meaning)";
    } else if (type == "Listening-EngM2EngW") {
      var subtype =
        "Vocabulary Listening Writing: Listen and write what you hear (English meaning to English word)";
    } else if (type == "Eng1") {
      var subtype = "Vocabulary Writing (English to English1)";
    } else {
      var subtype = "Vocabulary Writing (English to English2)";
    }
    if (student.user.student_id) {
      if (!resultRecordId) {
        console.log("Data submitted to API.", updatedUserAnswerlist);
        const option = {
          // category: location.state.vocabulary.id,
          total_score: sum,
          Studentid: student.user.student_id,
          Classid: student.user.Student.class_name,
          type: "Study",
          sub_type: subtype,
          program_id: program_id,
          vocab_id: program_id,
          result: JSON.stringify(updatedUserAnswerlist),
          vocab_q_start: q_start,
          vocab_q_end: q_end,
          chunk_id:chunk_id,
          //category_result:JSON.stringify(categoryTypeAnswers),
        };

        console.log(option, "opt");
        const response1 = await axios.post(
          "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
          option,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              // Add other headers if needed
            },
          }
        );
        if (response1) {
          setResultRecordId(response1.data.data.id);
        }
        //setShowResult(true);
      } else {
        const option = {
          // category: location.state.vocabulary.id,
          total_score: sum,
          Studentid: student.user.student_id,
          Classid: student.user.Student.class_name,
          type: "Study",
          sub_type: subtype,
          program_id: program_id,
          vocab_id: program_id,
          record_id: resultRecordId,
          result: JSON.stringify(updatedUserAnswerlist),
          vocab_q_start: q_start,
          vocab_q_end: q_end,
          // category_result:JSON.stringify(categoryTypeAnswers),
          //  total_questions:allQuestions.length,
          //    question_answered: questionAnswered,
          //    percentage: scoreprecentage,
          //    correct_answered: correctAnswered,
        };
        console.log(option, "opt");
        const response1 = await axios.post(
          "https://api.webtracktechnologies.com.au:8002/homework/save-vocab-result",
          option,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
              // Add other headers if needed
            },
          }
        );
      }
    }
  };
  const handleRetryAnswerSubmit = () => {
    setCurrentAnswer("");
    setShowCorrectAnswer(false);
    if (currentQuestionIndex === sentences.length - 1) {
      setCurrentQuestionIndex(0);
      setIsLastQuestion(false);
      //setIsLastQuestion(true);
    } else {
      //setShowCorrectAnswer(false);
      setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
    }
  };

  useEffect(() => {
    var vocab_id = program_id;
    dispatch(quizActions.getVocabWritingQuestions({ vocab_id, q_start }));
  }, []);
  //console.log(sentences, "get quiz");
  useEffect(() => {
    // Play the audio when the component mounts

    const audio = audioRef.current;

    if (audio && audioSrc) {
      console.log(audioSrc, "get src");
      audio.src = audioSrc;
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
  }, [audioSrc]);
  //console.log(quiz,'quiz');return false;
  const percentage = (score / sentences.length) * 100;
  const remaining = 100 - percentage;
  const data = [{ name: "Your score", score: percentage, total: 100 }];
  if (type == "Listening-EngW2EngM") {
    var subtype =
      "Vocabulary Listening Writing: Listen and write the correct meaning (English word to English meaning)";
  } else if (type == "Listening-EngM2EngW") {
    var subtype =
      "Vocabulary Listening Writing: Listen and write what you hear (English meaning to English word)";
  } else if (type == "Eng1") {
    var subtype = "Vocabulary Writing (English to English1)";
  } else {
    var subtype = "Vocabulary Writing (English to English2)";
  }
  const handleSpeakerClick = () => {
    // Play the audio when the speaker icon is clicked
    // Update audioRef with the current audioSrc
    // audioRef.current.src = audioSrc;

    // // Play the audio when the speaker icon is clicked
    // if (audioRef.current) {
    //   audioRef.current.play();
    // }
    const audio = audioRef.current;
    if (audio && audioSrc) {
      console.log("Attempting to play audio:", audioSrc);
      audio.src = audioSrc;
      audio.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    } else {
      console.error("Audio or audio source is missing");
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setPendingSuggestion(suggestion);
  };
  const handleInputFocus = () => {
    if (pendingSuggestion) {
      setCurrentAnswer((prevAnswer) => `${prevAnswer} ${pendingSuggestion}`);
      setPendingSuggestion("");
    }
  };
  const handleHeartClick = (suggestion, index) => {
    setClickedHearts((prevClickedHearts) => [...prevClickedHearts, index]);
    if (suggestion) {
      setBlinkingWord(suggestion);
      let blinkCount = 0;

      const blinkInterval = setInterval(() => {
        setBlinkingWord((prev) => (prev ? "" : suggestion));
        blinkCount++;

        if (blinkCount === 6) {
          // 3 blinks (each blink includes appearing and disappearing)
          clearInterval(blinkInterval);
          setBlinkingWord("");
          setVisibleHearts((prev) =>
            prev.map((visible, i) => (i === index ? false : visible))
          );
        }
      }, 500); // Adjust blink speed if needed
    }
  };
  const raiseFlagForCorrectAnswer = () => {
    setRaiseFlag(true);
  };
  const handleRaiseFlagCancel = () => {
    // Handle form submission here
    setRaiseFlag(false);
  };
  const handleRaiseFlagSubmit = async () => {
    // Handle form submission here
    console.log("Input value:", correctValue);

    const matchingObject =
      dictionary && dictionary.data
        ? dictionary.data.find((item) => item.word === clickedWord)
        : {};
    //console.log('Selected date:', matchingObject);return false;
    const option = {
      word: clickedWord,
      newwordmeaning: correctValue,
      newkoreanword: koreanWordValue,
      oldkoreanword: matchingObject.koreanword,
      oldwordmeaning: matchingObject.meaning,
      teacher_id:authUser.user.teacher_id,
    };
    // console.log(option, "opt")
    const response = await axios.post(
      "https://api.webtracktechnologies.com.au:8002/dictonary/reportword",
      option,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          // Add other headers if needed
        },
      }
    );

    if (response) {
      toast.success(
        "Submitted meaning/korean word successfully.It will be review and update by admin",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        }
      );

      setShowWrongWord(false);
    }
  };
  
  return (
    <section className="custom-sign-translate-question">
      <div class="container">
        {!showResult ? (
          <div class="stopwatch-container">
            <div className="row">
              <div class="col-md-10">
                <h2>{subtype}</h2>
                {!showCorrectAnswer ? (
                  <>
                    {type == "Listening-EngW2EngM" ||
                    type == "Listening-EngM2EngW" ? (
                      <>
                        <span
                          role="img"
                          aria-label="speaker"
                          style={{ fontSize: "40px", cursor: "pointer" }}
                          onClick={handleSpeakerClick}
                        >
                          🔊
                        </span>
                        <p></p>
                        <audio
                          ref={audioRef}
                          style={{ display: "none" }}
                          controls
                        >
                          <source
                            src={
                              sentences[currentQuestionIndex]
                                ? sentences[currentQuestionIndex].audio
                                : ""
                            }
                            type="audio/mp3"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                      </>
                    ) : (
                      <h3 style={{ color: "#0f59a8" }}>
                        Q.
                        {sentences[currentQuestionIndex] &&
                        sentences[currentQuestionIndex].question
                          ? sentences[currentQuestionIndex].question
                          : ""}
                      </h3>
                    )}
                    <div className="answer-user">
                      <h3 style={{ color: "#0f59a8" }}>A.</h3>{" "}
                      <input
                        type="text"
                        value={
                          currentAnswer +
                          (blinkingWord ? ` ${blinkingWord}` : "")
                        }
                        onFocus={handleInputFocus}
                        onChange={(e) => setCurrentAnswer(e.target.value)}
                      />
                    </div>
                    {isLastQuestion ? (
                      <button onClick={handleSubmit}>Submit</button>
                    ) : (
                      <button onClick={handleAnswerSubmit}>Enter</button>
                    )}
                  </>
                ) : (
                  ""
                )}
                <div className="hearts">
                  {Array.from({ length: totalSentences }).map(
                    (_, index) =>
                      !clickedHearts.includes(index) && (
                        <span
                          key={index}
                          onClick={() =>
                            handleHeartClick(
                              sentences[currentQuestionIndex]?.answer || "",
                              index
                            )
                          }
                          className="heart-icon"
                          style={{
                            cursor: "pointer",
                            fontSize: "24px",
                            color: "red",
                            margin: "0 5px",
                          }}
                        >
                          ❤️
                        </span>
                      )
                  )}
                </div>
                {showCorrectAnswer && (
                  <div>
                    <p>Let's try again.</p>
                    {type == "Listening-EngW2EngM" ||
                    type == "Listening-EngM2EngW" ? (
                      <>
                        <span
                          role="img"
                          aria-label="speaker"
                          style={{ fontSize: "40px", cursor: "pointer" }}
                          onClick={handleSpeakerClick}
                        >
                          🔊
                        </span>
                        <p></p>
                        <audio
                          ref={audioRef}
                          style={{ display: "none" }}
                          controls
                        >
                          <source
                            src={
                              sentences[currentQuestionIndex]
                                ? sentences[currentQuestionIndex].audio
                                : ""
                            }
                            type="audio/mp3"
                          />
                          Your browser does not support the audio tag.
                        </audio>
                      </>
                    ) : (
                      <h3 style={{ color: "#0f59a8" }}>Q.{currentQuestion}</h3>
                    )}
                    <p>
                      The correct answer is{" "}
                      <span className="correct-answer-sec">{correct}</span>
                    </p>
                    <button onClick={handleRetryAnswerSubmit}>Yep!</button>
                    {/* <p className="accuracy">Accuracy: {accuracyPercent}%</p> */}
                    <p>You answered </p>
                    <p className="you-answered">{currentAnswer}</p>
                    <button onClick={handleRetryAnswerSubmit}>Yep!</button>
                  </div>
                )}
              </div>

              <div class="col-md-2">
                <div class="stopwatch-box">
                  <h6 class="g-bg">Questions Answered</h6>
                  <p class="result">{answeredQuestions}</p>
                  <h6 class="b-bg">Stopwatch</h6>
                  {!inactive ? (
                    <div className="watch">
                      <p className="hr">
                        <span>{hours}</span>
                        <span className="title">HR</span>
                      </p>
                      <p className="min">
                        <span>{minutes}</span>
                        <span className="title">MIN</span>
                      </p>
                      <p className="sec">
                        <span>{seconds}</span>
                        <span className="title">SEC</span>
                      </p>
                    </div>
                  ) : (
                    <div className="paused-text">PAUSED</div>
                  )}
                  <h6 class="g-bg">My Score</h6>
                  <p class="result">{score}</p>
                  {showCorrectAnswer && <i class="fa fa-flag" aria-hidden="true" onClick={raiseFlagForCorrectAnswer}></i>}
                  <Modal show={raiseFlag} onHide={handleRaiseFlagCancel}>
          <Modal.Header closeButton>
            <Modal.Title>
            Suggest your answer here.
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ padding: "15px" }}>
            <div>
              <label htmlFor="inputField">Your Answer</label>
              <input type="text" id="inputField" value={currentAnswer} />
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleRaiseFlagSubmit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <h2>Quiz Result!</h2>

            {/*<BarChart
          xAxis={[{ scaleType: 'band', data: ['Your score'] }]}
          series={[{ data: [percentage], color: "#3a58e9b8", stack: 'total' }, { data: [100 - percentage], color: "#992525", stack: 'total' }]}
          width={500}
          height={300}

        />*/}

            <BarChart width={500} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis domain={[0, 100]} />
              <Tooltip />
              <Legend />
              <Bar dataKey="score" fill="#3a58e9b8" stackId="a" barSize={200} />
              <Bar dataKey="total" fill="#e93a3a" stackId="a" barSize={200} />
            </BarChart>

            <span className="score">
              <div className="score-wrap">
                <span
                  className="stars-active"
                  style={{ width: `${percentage}%` }}
                >
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
                <span className="stars-inactive">
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                  <i className="fa fa-star" aria-hidden="true"></i>
                </span>
              </div>
            </span>
            <Table style={{ margin: "20px", width: "95%" }}>
              <thead>
                <th>Your Raw Score</th>
                <th>
                  {score} / {sentences.length}
                </th>
              </thead>
              <thead>
                <th>Your Score</th>
                <th>{percentage.toFixed(2)}%</th>
              </thead>
            </Table>

            <div className="quiz-results">
              <h3>Quiz Results:</h3>
              <Table striped bordered responsive>
                <thead>
                  <tr>
                    <th></th>
                    <th>Question</th>
                    <th>Correct Answer</th>
                    <th>Your Answer</th>
                  </tr>
                </thead>
                <tbody>
                  {userAnswers.length > 0
                    ? userAnswers.map((answer, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{answer.question}</td>
                          <td>{answer.correctAnswer}</td>
                          <td>{answer.userAnswer}</td>
                        </tr>
                      ))
                    : ""}
                </tbody>
              </Table>
            </div>
          </div>
        )}
      </div>
    </section>
  );
}
