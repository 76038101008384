import { useEffect,useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { vocabdiagnosticActions } from '_store';

import { history } from '_helpers';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { useParams } from "react-router-dom";
import Confetti from './Confetti';
// Register the necessary Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export { VocabularyDiagnosticResult };

function VocabularyDiagnosticResult() {
  const dispatch = useDispatch();
  const { school,type, skip, retest } = useParams();
  const { user: authUser } = useSelector((x) => x.auth);
  const { vocabdiagnos } = useSelector((x) => x.vocabdiagnos);
  const student = JSON.parse(localStorage.getItem('user'));
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0');
  const day = today.getDate().toString().padStart(2, '0');
  const date = `${year}-${month}-${day}`;
  const [graphData, setGraphData] = useState({
            labels: [],
            datasets: [
              {
                label: 'Average',
                data: [],
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          });
  const [graphOptions, setGraphOptions] = useState({
            responsive: true,
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Average Scores',
              },
            },
          });

  useEffect(() => {
    if(student && student.user.student_id){
      const studentId = student.user.student_id;
      dispatch(vocabdiagnosticActions.getVocabDiagnosticResult({studentId, school, type, skip}));
    }
    if(student && student.user.teacher_id){
      const teacherId = student.user.teacher_id;
    dispatch(vocabdiagnosticActions.getVocabDiagnosticResultForTeacher({ teacherId, school, type, skip }));
    }
    

 }, []);
 
 useEffect(() => {

    if(vocabdiagnos && vocabdiagnos.data ){//console.log(vocabdiagnos.data.Vocabularyresults,'get vocab');return false;
        const labels = vocabdiagnos.data.Vocabularyresults.map(item => item.vocab);
        const dataPoints  = vocabdiagnos.data.Vocabularyresults.map(item => item.average);

        var data = {
            labels: labels,
            datasets: [
              {
                label: 'Average',
                data: dataPoints,
                backgroundColor: 'rgba(75, 192, 192, 0.2)',
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 1,
              },
            ],
          };
          var options = {
            responsive: true,
            scales: {
              y: {
                min: 0,
                max: 100,
                ticks: {
                  stepSize: 20,
                },
              },
            },
            plugins: {
              legend: {
                position: 'top',
              },
              title: {
                display: true,
                text: 'Average Scores',
              },
            },
          };
          setGraphData(data);
          setGraphOptions(options);
    }else{
        var data = {};
        var options = {};
    }
   

 }, [vocabdiagnos]);

    return (

      <section className="custom-sign-vocab-diagnostic">

<div className="container">
  <div className="row">
    <div className='col-md-2'>
    <a href={`/vocabulary-diagnostic-test/${school}/${type}/true`}><button type="button" className='btn btn-primary'>Re-Test</button></a>
    </div>
 
    <h2>Vocabulary Diagnostic Result</h2>
<div className='col-md-6'>
<Bar data={graphData} options={graphOptions} />
</div>
<div className='col-md-6'>
<img src="/images/1.jpg" className='excellent-img' />
</div> 
<div className='set-report-link'><a href={`/vocabulary-score-report/${student.user.student_id}/${school}/${type}/${date}`}>View Report</a></div>
</div>
<div className='col-md-12'>
            <Confetti />
          </div>
</div>



    </section>
      
    )
}
