import { useEffect , useState, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    studentActions,
    audioActions,
    homeworkActions,
    readingActions,
  } from "_store";
import { AudioRecorder, useAudioRecorder } from "react-audio-voice-recorder";
import axios from "axios";
import { Modal, Button } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import $ from 'jquery';
const settings = {
    bars: 7,
    spacing: 3,
    width: 5,
    height: 100
  };
export { ReadOutLoud };

function ReadOutLoud() {
    const dispatch = useDispatch();
    const { user: authUser } = useSelector((x) => x.auth);
    const { homework_id } = useParams();
    const { homework } = useSelector((x) => x.homework);
    const { reading } = useSelector((x) => x.reading);
    const { student } = useSelector((x) => x.student);
    const { myrecordings } = useSelector((x) => x.myrecordings);
    const [textValue, setTextValue] = useState("");
    const initialMinutes = 2;
    const initialSeconds = initialMinutes * 60;
    const [teacherResult, setTeacherResult] = useState(null);
    const [totalSeconds, setTotalSeconds] = useState(initialSeconds);
    const audioRef = useRef(null);
    const [audioFile, setAudioFile] = useState("");
    const [filePath, setFilePath] = useState("");
    const [audioQuality, setAudioQuality] = useState("");
    const [recordId, setRecordId] = useState("");
    const recorderControls = useAudioRecorder();
    const [showDialog, setShowDialog] = useState(false);
    const { student_teacher_result } = useSelector(
      (x) => x.student_teacher_result
    );
    const [apiResult, setApiResult] = useState(null);
    const [error, setError] = useState(false);
    const [contentValue, setContent] = useState(0);
    const [fluencyValue, setFluency] = useState(0);
    const refs = useRef([]);
    const volumemeter = useRef(0);
    const volumeRefs = useRef(new Array(settings.bars));
    const [imageSource, setImageSource] = useState('/images/record.png');
    const [textAudio, setTextAudio] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [highlightedIndex, setHighlightedIndex] = useState(0);
    const [textForXml, setTextForXml] = useState('');
   
    useEffect(() => {
      if (recordId) {
        var file = filePath;
        //var text = (homework && homework.data) ? homework.data.homework_text :'';
       // var type = 'Read Out Loud';
        const string = textValue;
        var text = (textForXml) ? textForXml : textValue;//JSON.stringify(wordList);
        if(homework && homework.data){
          if(homework.data.type){
            var type = 'Read Out Loud';
            var sub_type = 'Read Out Loud';
          }
          
        }
        const studentInfo = JSON.parse(localStorage.getItem('user'));
        var class_id = studentInfo.user.Student.class_name;
    
        dispatch(readingActions.readingaudiorecording({ recordId,text, file, type,sub_type,class_id,homework_id }));

      }
    }, [recordId]);
    useEffect(() => {
      if (reading) {
         window.location.href = '/ReadOutLoudGrader/'+recordId;
      }
    }, [reading]);
    console.log(reading,'get user');
    const blobToBase64 = (blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      return new Promise((resolve) => {
        reader.onloadend = () => {
          resolve(reader.result);
        };
      });
    };
  
    const addAudioElement = async (blob) => {
      const url = URL.createObjectURL(blob);
      //alert("lalit");
  
      blobToBase64(blob).then((res) => {
        // do what you wanna do
        console.log(res, "blob"); // res is base64 now
      });
      //
      const formData = new FormData();
      formData.append("audio", blob);
      formData.append("user", authUser.user.student_id);
      formData.append("homework_id", homework_id);
      var text = (homework && homework.data) ? homework.data.homework_text :'';
      formData.append("text", text);
      formData.append("type", 'Read Out Loud');
      formData.append("sub_type", 'Read Out Loud');
      const response = await axios.post(
        "https://api.webtracktechnologies.com.au:8002/audio-recording",
        formData,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
            // Add other headers if needed
          },
        }
      );
      setAudioFile(response.data.data);
      setFilePath(response.data.filepath);
      setRecordId(response.data.audio_record.id);
      //  const audio1 = document.createElement("audio");
      //   audio1.src = url;
      //   audio1.controls = true;
    };
  
    const handleTextareaChange = (event) => {
      setTextValue(event.target.value);
    };
  
    useEffect(() => {
      if (recorderControls.isRecording) {
        const interval = setInterval(() => {
          if (totalSeconds > 0) {
            setTotalSeconds(totalSeconds - 1);
          }
        }, 1000);
  
        return () => clearInterval(interval);
      }
    }, [recorderControls.isRecording, totalSeconds]);
  
    const formatTime = (timeInSeconds) => {
      const minutes = Math.floor(timeInSeconds / 60);
      const seconds = timeInSeconds % 60;
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
  

    useEffect(() => {
      var rawdata = '';
      //var rawdata = true;
      dispatch(homeworkActions.getHomeWorkByRecordId({ homework_id, rawdata }));
      $('.audio-recorder-mic').attr('src',imageSource);
    }, [homework_id]);
    useEffect(() => {
      if (homework?.data) {
        // Set text value based on the available property
        if (homework.data.homework_text) {
          setTextValue(homework.data.homework_text);
        } else {
          setTextValue(homework.data.homework_email_text);
        }
    
        // Check if the 'Textbook Read Out Loud' words array exists and has elements
        const readOutLoudWords = homework.data.Programs?.['Textbook Read Out Loud']?.words;
        
        if (readOutLoudWords && readOutLoudWords.length > 0) {
          // Set audio file link
          setTextAudio(readOutLoudWords[0]?.Bookwordaudio?.[0]?.filelink || '');
    
          // Set text for XML
          setTextForXml(readOutLoudWords[0]?.text || '');
        }
      }
    }, [homework]);
    
  
    const startRecording = () => {
      
        recorderControls.startRecording;
      
    }

    const getMedia = () => {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(stream => {
          const audioContext = new AudioContext();
          const analyser = audioContext.createAnalyser();
          const microphone = audioContext.createMediaStreamSource(stream);
          const javascriptNode = audioContext.createScriptProcessor(2048, 1, 1);
          analyser.smoothingTimeConstant = 0.4;
          analyser.fftSize = 1024;
          microphone.connect(analyser);
          analyser.connect(javascriptNode);
          javascriptNode.connect(audioContext.destination);
          javascriptNode.onaudioprocess = () => {
            var array = new Uint8Array(analyser.frequencyBinCount);
            analyser.getByteFrequencyData(array);
            var values = 0;
            var length = array.length;
            for (var i = 0; i < length; i++) {
              values += array[i];
            }
            volumemeter.current = values / length;
          };
        })
        .catch(function(err) {
          /* handle the error */
        });
    };
  
    useEffect(getMedia, []);
    useEffect(() => {
      const intervalId = setInterval(() => {
        volumeRefs.current.unshift(volumemeter.current);
        volumeRefs.current.pop();
        if(refs.current.length > 0){
          //console.log(refs.current,'get reference');return false;
          for (let i = 0; i < refs.current.length; i++) {
  if(refs.current[i] != '' && refs.current[i] != null){
  
    refs.current[i].style.transform = `scaleY(${volumeRefs.current[i] /
      100})`;
  }
          }
        }
      }, 20);
      return () => {
        clearInterval(intervalId);
      };
    }, []);
  
    const createElements = () => {
      let elements = [];
  
      for (let i = 0; i < settings.bars; i++) {
        elements.push(
          <div
            ref={ref => refs.current.push(ref)}
            key={`vu-${i}`}
            style={{
              position: "absolute",
              background: "#5ac63d",
              borderRadius: settings.width + "px",
              width: settings.width + "px",
              height: Math.sin((i / settings.bars) * 4) * settings.height + "px",
              left: i * (settings.width + settings.spacing) + "px",
              top:
              settings.height / 2 -
              Math.sin((i / settings.bars) * 4) * settings.height / 2 +
              "px"
            }}
          />
        );
      }
      return elements;
    };
    useEffect(() => {
      if(recorderControls.stopRecording){
        $('.audio-recorder-mic').attr('src',imageSource);
      }
  }, [recorderControls.stopRecording]); 

  const handleListenClick = () => {
    setShowModal(true);
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };
  // const handlePlayAll = () => {
  //   // Play audio files for all sentences
  //   textAudio.forEach((word, index) => {
  //     setTimeout(() => {
  //       playAudio(index);
  //     }, index * 6000); // Delay each audio play by 3 seconds
  //   });
  // };

  const playAudio = (index) => {
    const audioElement = document.getElementById(`audio-${index}`);
    if(audioElement){
      audioElement.play();
    }

    setHighlightedIndex(index); // Highlight the currently playing word
  };

  console.log(homework,'get homework');
    return (
      
      <section>
        {!reading ? (
          <div className="test">
            <h2>TEXTBOOK READ OUT LOUD</h2>
            {/* <p>{(homework && homework.data) ? homework.data.homework_email_text : 'Your Topic'}</p> */}
            {(homework && homework.data && homework.data.program_id && homework.data.Library && homework.data.Library.lprograms )? 
            <>
             <p>{(homework && homework.data) ? homework.data.Library.lprograms[0].words[0].topic : 'Your Topic'}</p>
             <p className="test-review">{homework.data.Library.lprograms[0].words[0].text}</p>
            </>
            :
            <>
            <p>{(homework && homework.data) ? homework.data.homework_email_text : 'Your Topic'}</p>
            <p className="test-review">{(homework && homework.data && homework.data.Programs && homework.data.Programs['Textbook Read Out Loud'] && homework.data.Programs['Textbook Read Out Loud'].words) ? homework.data.Programs['Textbook Read Out Loud'].words[0].text : textValue}</p>
</>
            }
<Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Listen</Modal.Title>
        </Modal.Header>
        <Modal.Body className='read-out-loud-listen'>
          <ul>
            {(homework && homework.data && homework.data.Programs && homework.data.Programs['Textbook Read Out Loud'] && homework.data.Programs['Textbook Read Out Loud'].words) 
            ?
              <li key={0} attr-audio={textAudio}  onClick={() => playAudio(0)} style={{ fontWeight: 0 === highlightedIndex ? 'bold' : 'normal' }}>
                 {homework.data.Programs['Textbook Read Out Loud'].words[0].text}
                <audio id={`audio-${0}`} src={textAudio} />
                </li>:''
           
           }
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
          <Button variant="primary" onClick={()=>playAudio(0)}>
            Play
          </Button>
        </Modal.Footer>
      </Modal>
                  
            <div className="mt-4">
              <div className="block my-recording-micro">
                <i className="fa fa-microphone big" aria-hidden="true" />
                {
          (recorderControls.isRecording ) ? 
          <div className="micro-sound-meter" style={{ position: "relative" }}>{createElements()}</div>: ''
        }
                <p>Countdown Timer</p>
  
               
                {recorderControls.isRecording ? (
  
                  (!showDialog) ?
                  <>
                  <p>
                  {recorderControls.isRecording
                    ? formatTime(totalSeconds)
                    : "02:00"}
                </p>
                  <button
                    onClick={recorderControls.stopRecording}
                    className="test-btn stop"
                  >
                    Stop
                  </button>
                  <button onClick={() => handleListenClick()}>Listen</button>
                  </>
                  :
                  ''
                ) : (
                  (!showDialog) ? 
                  <>
                  <button
                    className="test-btn"
                    onClick={() => startRecording()}
                  >
                    
                    <AudioRecorder
                      onRecordingComplete={(blob) => addAudioElement(blob)}
                      recorderControls={recorderControls}
                      audioTrackConstraints={{
                        noiseSuppression: true,
                        echoCancellation: true,
                      }}
                      downloadOnSavePress={true}
                      downloadFileExtension="mp3"
                      showVisualizer={true}
                    />
                  </button>
                   <button onClick={() => handleListenClick()}>Listen</button>
                   </>
                  : ''
                )}
  
                {audioFile ? (
                  <>
                    <audio className="hide" ref={audioRef} controls>
                      <source src={audioFile} type="audio/mp3" />
                      Your browser does not support the audio element.
                    </audio>
                   
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="container">
          <div className="row audio-result">
            <div className="col-lg-12">
              {reading ? (
                <>
                  <audio ref={audioRef} controls>
                    <source src={audioFile} type="audio/mp3" />
                    Your browser does not support the audio element.
                  </audio>
                  <button
                    onClick={()=>window.location.reload()}
                    className="test-btn"
                  >
                    Record Again
                  </button>
                  <div className="result-table">
          <table>
            <tbody>
              <tr>
                <th colSpan={2}>Audio Quality</th>
                <th></th>
                <th>No Recording</th>
                <th></th>
                <th>Too Soft</th>
                <th></th>
                <th>Too Loud</th>
                <th></th>
                <th>Too Noisy</th>
                <th></th>
                <th>Beginning Cut-off</th>
                <th></th>
                <th>End Cut-off</th>
                <th></th>
                <th>Audio Quality Good</th>
                <th></th>
              </tr>
              <tr>
                <td colSpan={2}>Total Speaking Time</td>
                <td>0</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td colSpan={2} rowSpan={2}>
                  Fluency
                </td>
                <td>0</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td />
                <td />
              </tr>
              <tr>
                <td>
                  Extremely Slow or No Response <span>(less than 60wpm)</span>
                </td>
                <td>
                  Slow <span>(61-90wpm)</span>
                </td>
                <td>
                  Average <span>(91-120wpm)</span>
                </td>
                <td>
                  Relatively Fast <span>(121-150wpm)</span>
                </td>
                <td>
                  Fast <span>(Over 151wpm)</span>
                </td>
                <td />
                <td />
              </tr>
              <tr>
                <td colSpan={2} rowSpan={2}>
                  Accuracy
                </td>
                <td>0</td>
                <td>1</td>
                <td>2</td>
                <td>3</td>
                <td>4</td>
                <td />
                <td />
              </tr>
              <tr>
                <td>No Recording or less than 10%</td>
                <td>21-40%</td>
                <td>41-60%</td>
                <td>61-80%</td>
                <td>81-100%</td>
                <td />
                <td />
              </tr>
              <tr>
                <td rowSpan={3}>Breakdown of Errors</td>
                <td>Substitution {(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nSub[0] : 0}</td>
                <td>1 %</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>Deletion {(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nDel[0] : 0}</td>
                <td>99 %</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>Insertion {(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nIns[0] : 0}</td>
                <td>0 %</td>
                <td />
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td>Total Number of Errors</td>
                <td>{(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? 
                reading.data.message.AAResult[0].analysis[0].readingSummary[0].nErr[0] : 0}/ {(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nRef[0]: 0}</td>
                <td>100 %</td>
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td colSpan={2}>Number of Words in Your Answer</td>
                <td>{(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nHyp[0] : 0}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
              <tr>
                <td colSpan={2}>Number of Target Words</td>
                <td>{(reading && reading.data && reading.data.message && reading.data.message.AAResult) ? reading.data.message.AAResult[0].analysis[0].readingSummary[0].nRef[0] : 0}</td>
                <td />
                <td />
                <td />
                <td />
                <td />
              </tr>
            </tbody>
          </table>
        </div>
                </>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </section>
    )
}
