import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { studentrecordingsActions, homeworkActions ,studentActions} from "_store";
import { Modal, Button } from 'react-bootstrap';
import { history } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
export { WriteEssay };

function WriteEssay() {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState("");
  const [textInput, setTextInput] = useState("");
  const [textareaInput, setTextareaInput] = useState("");

  const handleRadioChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const handleTextInputChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleTextareaChange = (e) => {
    setTextareaInput(e.target.value);
  };

  const handleSubmit = () => {
    alert(`Submitted:\nOption: ${selectedOption}\nText: ${textInput}\nTextarea: ${textareaInput}`);
  };

  const handleClear = () => {
    setSelectedOption("");
    setTextInput("");
    setTextareaInput("");
  };

  const handleLog = () => {
    console.log(`Option: ${selectedOption}, Text: ${textInput}, Textarea: ${textareaInput}`);
  };

  return (
    <section className="custom-sign write-essay">
    <div className="container">
	<div>
  <label style={{ display: "block", marginBottom: "5px" }}>
          <input
            type="radio"
            name="option"
            value="Option 1"
            checked={selectedOption === "Option 1"}
            onChange={handleRadioChange}
            style={{ marginRight: "10px", appearance: "auto" }} // Ensure radio button is visible
          />
          Option 1
        </label>
        <br />
        <label>
          <input
            type="radio"
            name="option"
            value="Option 2"
            checked={selectedOption === "Option 2"}
            onChange={handleRadioChange}
          />
          Option 2
        </label>
      </div>

      <div style={{ margin: "10px 0" }}>
        <label>
          Text Input:
          <input
            type="text"
            value={textInput}
            onChange={handleTextInputChange}
            style={{ marginLeft: "10px" }}
          />
        </label>
      </div>

      <div style={{ margin: "10px 0" }}>
        <label>
          Textarea:
          <textarea
            value={textareaInput}
            onChange={handleTextareaChange}
            rows="4"
            cols="30"
            style={{ display: "block", marginTop: "5px" }}
          ></textarea>
        </label>
      </div>

      <div>
        <button onClick={handleSubmit}>Submit</button>
        <button onClick={handleClear} style={{ marginLeft: "10px" }}>
          Clear
        </button>
        <button onClick={handleLog} style={{ marginLeft: "10px" }}>
          Log to Console
        </button>
      </div>
  </div>

</section>
    )
}
