import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { history } from '_helpers';
import { authActions } from '_store';

export { Login };

function Login() {
    const dispatch = useDispatch();
    const authUser = useSelector(x => x.auth.user);
   // console.log(authUser,'get user');return false;
    const authError = useSelector(x => x.auth.error);
    const [rememberMe, setRememberMe] = useState(false);
    useEffect(() => {
        // redirect to home if already logged in
        if (authUser){
          if(authUser.user.type == 'teacher'){
            history.navigate('/classroom');
          }
        } 

        // Check if credentials are stored in localStorage
        const savedEmail = localStorage.getItem('email');
        const savedPassword = localStorage.getItem('password');
        if (savedEmail && savedPassword) {
            
            setRememberMe(true);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authUser]);
    useEffect(() => {
      localStorage.clear(); // Clears all items in localStorage
      sessionStorage.clear(); // Clears all items in sessionStorage

      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
    // form validation rules 
    const validationSchema = Yup.object().shape({
        email: Yup.string().required('Email is required'),
        password: Yup.string().required('Password is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };

    // get functions to build form with useForm() hook
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors, isSubmitting } = formState;

    async function onSubmit({ email, password }) {
      try {
          const resultAction = await dispatch(authActions.login({ email, password }));

          // If the action is fulfilled (successful)
          if (authActions.login.fulfilled.match(resultAction)) {
              console.log('Login successful:', resultAction.payload);
              if(resultAction.payload.user.student_id){
                toast.success('You are logged in successfully.', {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  });
                window.location.href = '/student-homework'; 
              }
              // Handle "Remember Me" logic
              if (rememberMe) {
                localStorage.setItem('email', email);
                localStorage.setItem('password', password);
                setRememberMe(true);
            } else {
                localStorage.removeItem('email');
                localStorage.removeItem('password');
                setRememberMe(false);
            }
              // Handle successful login here, e.g., navigate to another page
          } else {
              // If login was rejected (error), handle it here
              console.error('Login failed:', resultAction.payload);
              return false;
              // Show an error message to the user
          }
      } catch (error) {
          // This will catch any unexpected errors in the dispatch process
          console.error('An unexpected error occurred during login:', error);
          return false;
          // Show a generic error message to the user
      }
  }
  const handleRememberMeChange = () => {
    setRememberMe(!rememberMe);
};

    return (

        <section className="custom-sign">
          <div className="container-fluid">
            <div className="row">
              <div
                className="col-lg-6 col-md-12 sign-left-bg"
                style={{ backgroundImage: "url(images/signin-img.webp)" }}
              >
                <div className="custom-sign-left" />
              </div>
              <div className="col-lg-6 col-md-12">
                <div className="sign-in-content">
                  <h3>
                    Welcome to Like Study <br />
                    Learning Experince
                  </h3>
                  <ul>
                    <li>#study</li>
                    <li>#test</li>
                    <li>#library</li>
                    <li>#vocabulary</li>
                    <li>#recorders</li>
                  </ul>
                </div>
              </div>
              <div className="signin-form">
                <h3>Sign In</h3>
                <p className="custom-para">
                  Enter your email/id and password for signing in. Thanks
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="mb-3">
                    <input
                      type="text"
                      {...register('email')} 
                      className={`form-control ${errors.email ? 'is-invalid' : ''}`}
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      placeholder="Email/ID"
                      name="email"
                    />
                    <div className="invalid-feedback">{errors.email?.message}</div>
                  </div>
                  <div className="mb-3">
                    <input
                      type="password"
                      {...register('password')}
                      className={`form-control ${errors.password ? 'is-invalid' : ''}`}
                      id="exampleInputPassword1"
                      placeholder="Password"
                      name="password"
                    />
                    <div className="invalid-feedback">{errors.password?.message}</div>
                  </div>
                  <div className="signin-form-btn">
                    <input
                      className="custom-button"
                      type="submit"
                      defaultValue="LOGIN"
                    />
                  </div>
                  <div className="pass-check">
                    <ul>
                      <li>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultValue=""
                            id="flexCheckDefault"
                            checked={rememberMe}
                            onChange={handleRememberMeChange}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          >
                            Remember Me
                          </label>
                        </div>
                      </li>
                      <li>
                        <a href="#">Forgot Password</a>
                      </li>
                    </ul>
                    <p className="custom-para pass-creation">
                      Not a member yet? <a href="/signup">Create an account</a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
   
      
    )
}
