import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'study';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const studyActions = { ...slice.actions, ...extraActions };
export const studyReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        searchPassageFromDB: searchPassageFromDB(),
        createStudyTest:createStudyTest(),
        studyTestList:studyTestList(),
        createNewTestAssign:createNewTestAssign(),
        assignTestList:assignTestList(),
        deleteAssignTest:deleteAssignTest(),
        editStudyTest:editStudyTest(),
        deleteStudyTest:deleteStudyTest(),
        studentStudyTestList:studentStudyTestList(),
        getTestListByLevel:getTestListByLevel(),
        assignedProgramQuestionList:assignedProgramQuestionList(),
        programQuestionInfo:programQuestionInfo(),
        getSubprogramsForStudy:getSubprogramsForStudy(),
        getSubprogramsForStudyGrammar:getSubprogramsForStudyGrammar(),
        getSubprogramsForStudySpeaking:getSubprogramsForStudySpeaking(),
        getProgramResultBySubtype:getProgramResultBySubtype(),
        getStudyTestResults:getStudyTestResults(),
        getSubprogramsForTeacherStudy: getSubprogramsForTeacherStudy(),

    };    

    function searchPassageFromDB() {
        return createAsyncThunk(
            `${name}/study`,
            async ({search}) => await fetchWrapper.post(`${baseUrl}/study/passage-search`,{search})
        );
    }

    function createStudyTest() {
        return createAsyncThunk(
            `${name}/study`,
            async ({test_title, level, teacher_id}) => await fetchWrapper.post(`${baseUrl}/studytest/create`,{test_title, level, teacher_id})
        );
    }

    function studyTestList() {
        return createAsyncThunk(
            `${name}/study`,
            async () => await fetchWrapper.get(`${baseUrl}/studytest/all`)
        );
    }

    function createNewTestAssign() {
        return createAsyncThunk(
            `${name}/study`,
            async ({test_id, class_id, student_id, type,teacher_id}) => await fetchWrapper.post(`${baseUrl}/studytest/assign`,{test_id, class_id, student_id, type, teacher_id})
        );
    }

    function assignTestList() {
        return createAsyncThunk(
            `${name}/study`,
            async ({studytest_id}) => await fetchWrapper.get(`${baseUrl}/studytest/assigned/`+studytest_id)
        );
    }

    function deleteAssignTest() {
        return createAsyncThunk(
            `${name}/study`,
            async ({studytest_id}) => await fetchWrapper.delete(`${baseUrl}/studytest/assigned/delete/`+studytest_id)
        );
    }

    function editStudyTest() {
        return createAsyncThunk(
            `${name}/study`,
            async ({test_title, level, teacher_id,studytest_id}) => await fetchWrapper.put(`${baseUrl}/studytest/edit/`+studytest_id,{test_title, level, teacher_id})
        );
    }

    function deleteStudyTest() {
        return createAsyncThunk(
            `${name}/study`,
            async ({studytest_id}) => await fetchWrapper.delete(`${baseUrl}/studytest/delete/`+studytest_id)
        );
    }

    function studentStudyTestList() {
        return createAsyncThunk(
            `${name}/study`,
            async ({student_id}) => await fetchWrapper.get(`${baseUrl}/studytest/student/`+student_id)
        );
    }

    function getTestListByLevel() {
        return createAsyncThunk(
            `${name}/study`,
            async ({study_test_id, level}) => await fetchWrapper.get(`${baseUrl}/studytest/level/`+study_test_id+'?level='+level)
        );
    }

    function assignedProgramQuestionList() {
        return createAsyncThunk(
            `${name}/study`,
            async ({test_id, student_id}) => await fetchWrapper.get(`${baseUrl}/studytest/assigned_programs/`+test_id+'?student_id='+student_id)
        );
    }

    function programQuestionInfo() {
        return createAsyncThunk(
            `${name}/study`,
            async ({program_id,type}) => await fetchWrapper.get(`${baseUrl}/studytest/program_data/`+program_id+'?type='+type)
        );
    }

    function getSubprogramsForStudy() {
        return createAsyncThunk(
            `${name}/study`,
            async ({level,student_id, type, date, test, page}) => await fetchWrapper.get(`${baseUrl}/studytest/studyprograms?level=`+level+'&student_id='+student_id+'&type='+type+'&date='+date+'&test='+test+'&page='+page)
        );
    }

    function getSubprogramsForStudyGrammar() {
        return createAsyncThunk(
            `${name}/study`,
            async ({program_id,q_start,blank}) => await fetchWrapper.get(`${baseUrl}/studytest/study-questions/`+program_id+'?q_start='+q_start+'&audio=true&blanks='+blank)
        );
    }

    function getSubprogramsForStudySpeaking() {
        return createAsyncThunk(
            `${name}/study`,
            async ({program_id,level}) => await fetchWrapper.get(`${baseUrl}/studytest/study-resquestions/`+program_id+'?level='+level)
        );
    }
    function getProgramResultBySubtype() {
        return createAsyncThunk(
            `${name}/study`,
            async ({vocab_id,sub_type,q_start,student_id}) => await fetchWrapper.get(`${baseUrl}/studytest/vocabtestresult?vocab_id=`+vocab_id+'&sub_type='+sub_type+'&q_start='+q_start+'&student_id='+student_id)
        );
    }

    function getStudyTestResults() {
        return createAsyncThunk(
            `${name}/study`,
            async ({student_id, date}) => await fetchWrapper.get(`${baseUrl}/studytest/report/`+student_id+'?date='+date)
        );
    }

    function getSubprogramsForTeacherStudy() {
        return createAsyncThunk(
            `${name}/study`,
            async ({level,student_id, type, date, test, page}) => await fetchWrapper.get(`${baseUrl}/studytest/teacher/studyprograms?level=`+level+'&student_id='+student_id+'&type='+type+'&date='+date+'&test='+test+'&page='+page)
        );
    }
    
}

function createExtraReducers() {
    return {
        ...searchPassageFromDB(),
        ...createStudyTest(),
        ...studyTestList(),
        ...assignTestList(),
        ...createNewTestAssign(),
        ...deleteAssignTest(),
        ...editStudyTest(),
        ...deleteStudyTest(),
        ...studentStudyTestList(),
        ...getTestListByLevel(),
        ...assignedProgramQuestionList(),
        ...programQuestionInfo(),
        ...getSubprogramsForStudy(),
        ...getSubprogramsForStudyGrammar(),
        ...getSubprogramsForStudySpeaking(),
        ...getProgramResultBySubtype(),
        ...getStudyTestResults(),
        ...getSubprogramsForTeacherStudy(),
    };

    function searchPassageFromDB() {
        var { pending, fulfilled, rejected } = extraActions.searchPassageFromDB;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function createStudyTest() {
        var { pending, fulfilled, rejected } = extraActions.createStudyTest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function studyTestList() {
        var { pending, fulfilled, rejected } = extraActions.studyTestList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function createNewTestAssign() {
        var { pending, fulfilled, rejected } = extraActions.createNewTestAssign;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function assignTestList() {
        var { pending, fulfilled, rejected } = extraActions.assignTestList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function deleteAssignTest() {
        var { pending, fulfilled, rejected } = extraActions.deleteAssignTest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function editStudyTest() {
        var { pending, fulfilled, rejected } = extraActions.editStudyTest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function deleteStudyTest() {
        var { pending, fulfilled, rejected } = extraActions.deleteStudyTest;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function studentStudyTestList() {
        var { pending, fulfilled, rejected } = extraActions.studentStudyTestList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getTestListByLevel() {
        var { pending, fulfilled, rejected } = extraActions.getTestListByLevel;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function assignedProgramQuestionList() {
        var { pending, fulfilled, rejected } = extraActions.assignedProgramQuestionList;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function programQuestionInfo() {
        var { pending, fulfilled, rejected } = extraActions.programQuestionInfo;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getSubprogramsForStudy() {
        var { pending, fulfilled, rejected } = extraActions.getSubprogramsForStudy;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getSubprogramsForStudyGrammar() {
        var { pending, fulfilled, rejected } = extraActions.getSubprogramsForStudyGrammar;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getSubprogramsForStudySpeaking() {
        var { pending, fulfilled, rejected } = extraActions.getSubprogramsForStudySpeaking;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getProgramResultBySubtype() {
        var { pending, fulfilled, rejected } = extraActions.getProgramResultBySubtype;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getStudyTestResults() {
        var { pending, fulfilled, rejected } = extraActions.getStudyTestResults;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function getSubprogramsForTeacherStudy() {
        var { pending, fulfilled, rejected } = extraActions.getSubprogramsForTeacherStudy;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const passage = action.payload;
              //console.log(user,'get register user');  return false;
                // store user details and jwt token in local storage to keep user logged in between page refreshes
                localStorage.setItem('passage', JSON.stringify(passage));
                state.passage = passage;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

}
