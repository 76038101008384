import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { history, fetchWrapper } from '_helpers';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// create slice

const name = 'results';
const initialState = createInitialState();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState,  extraReducers });

// exports

export const resultsActions = { ...slice.actions, ...extraActions };
export const resultsReducer = slice.reducer;

// implementation

function createInitialState() {
    return {
        // initialize state from local storage to enable user to stay logged in
        user: JSON.parse(localStorage.getItem('user')),
        error: null
    }
}


function createExtraActions() {
    const baseUrl = `https://api.webtracktechnologies.com.au:8002`;
   //const registerUrl = `${process.env.REACT_APP_API_URL}/frontend/auth/register/step-1`;

    return {
        getStudyVocabResults:getStudyVocabResults(),
        saveStudyVocabReadOutLoudResult: saveStudyVocabReadOutLoudResult(),
    };    

    function getStudyVocabResults() {
        return createAsyncThunk(
            `${name}/results`,
            async ({student_id}) => await fetchWrapper.get(`${baseUrl}/results/vocab_chunks/`+student_id)
        );
    }

    function saveStudyVocabReadOutLoudResult() {
        return createAsyncThunk(
            `${name}/results`,
            async ({recordId, score}) => await fetchWrapper.put(`${baseUrl}/results/updatescore/`+recordId, { score })
        );
    }

}

function createExtraReducers() {
    return {
        ...getStudyVocabResults(),
        ...saveStudyVocabReadOutLoudResult(),
    };

    function getStudyVocabResults() {
        var { pending, fulfilled, rejected } = extraActions.getStudyVocabResults;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const result = action.payload;
                state.results = result;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

    function saveStudyVocabReadOutLoudResult() {
        var { pending, fulfilled, rejected } = extraActions.saveStudyVocabReadOutLoudResult;
        return {
            [pending]: (state) => {
                state.error = null;
            },
            [fulfilled]: (state, action) => {
                const result = action.payload;
                state.results = result;

            },
            [rejected]: (state, action) => {
                console.log(action.error,'get er');
                state.error = action.error;
                toast.error(action.error.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    });
            }
        };
    }

}
