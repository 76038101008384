import { useEffect,useState } from 'react';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useSelector, useDispatch } from 'react-redux';
import { Modal, Button } from 'react-bootstrap';
import { resultsActions,studyActions } from '_store';
import { useParams } from "react-router-dom";
import { history } from '_helpers';
import axios from "axios";
import $ from 'jquery';

export { Study };

function Study() {
  const dispatch = useDispatch();
  const { passage } = useSelector((x) => x.passage);
  const { files } = useSelector((x) => x.files);
  const { results } = useSelector((x) => x.results);
  const [showTestDialog, setShowTestDialog] = useState(false);
  const [testText, setTestText] = useState('');
  const [testLevelText, setTestLevel] = useState('');
  const { user: authUser } = useSelector((x) => x.auth);
  const [selectedTest, setSelectedTest] = useState(null);
  const { record_id, level } = useParams();
  const [type, setType] = useState('');
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [assignedPrograms, setAssignedPrograms] = useState([]);
  const [sortedLevels, setSortedLevels] = useState([]);
  const [count, setCount] = useState(1);
  const [openSubMenu, setOpenSubMenu] = useState(false);
  const [loading, setLoading] = useState(true);
  console.log(level,'get level');
// useEffect( () => {

//  var student_id = authUser.user.student_id;
//  var type = 'vocab';
//  dispatch(studyActions.getSubprogramsForStudy({level,student_id, type}));
//   setType('Reading');

// }, []);
useEffect(() => {
  const fetchSubprograms = async () => {
    try {
      const student_id = authUser.user.student_id;

      // First API call
      const type1 = "vocab";
      //const type1 = "all";
      var date = '';
      var test = localStorage.getItem('test') || 1;
      var page = 'Study';
      await dispatch(studyActions.getSubprogramsForStudy({ level, student_id, type: type1,date, test,page }));

      await dispatch(resultsActions.getStudyVocabResults({student_id}));
      // Second API call after the first completes
       const type2 = "all";
       await dispatch(studyActions.getSubprogramsForStudy({ level, student_id, type: type2, date, test,page }));

    } catch (error) {
      console.error("Error fetching subprograms:", error);
    } finally {
      setLoading(false); // Hide loader after both API calls finish
    }
  };

  fetchSubprograms();
  setType("Reading"); // Assuming `setType` is defined
}, [dispatch, authUser]);

//console.log(assignedPrograms, 'get res');
console.log(passage,'get class');
//return false;

function numberToWord(number) {
  // Convert the number to words using a library or custom logic
  // For simplicity, let's assume a simple conversion for numbers 0-99
  const units = ['', 'ONE', 'TWO', 'THREE', 'FOUR', 'FIVE', 'SIX', 'SEVEN', 'EIGHT', 'NINE'];
  const teens = ['TEN', 'ELEVEN', 'TWELVE', 'THIRTEEN', 'FOURTEEN', 'FIFTEEN', 'SIXTEEN', 'SEVENTEEN', 'EIGHTEEN', 'NINETEEN'];
  const tens = ['', '', 'TWENTY', 'THIRTY', 'FORTY', 'FIFTY', 'SIXTY', 'SEVENTY', 'EIGHTY', 'NINETY'];

  if (number === 0) {
    return 'ZERO';
  }

  // Function to convert a two-digit number to words
  const twoDigitToWord = (num) => {
    if (num < 10) {
      return units[num];
    } else if (num < 20) {
      return teens[num - 10];
    } else {
      return tens[Math.floor(num / 10)] + (num % 10 !== 0 ? ` ${units[num % 10]}` : '');
    }
  };

  // Handle numbers up to 99
  if (number < 100) {
    return twoDigitToWord(number);
  } else {
    return 'OUT OF RANGE';
  }
}
const handleCheckboxChange = async (e, id, typeText, title) => {
  const isChecked = e.target.checked;
console.log(isChecked,'isChecked');
  // Extracting the program ID from the checkbox ID
  var idParam = id.split('-');

  // Find all checkboxes with the same title using jQuery
  const checkboxesWithSameTitle = $(`input[type="checkbox"][title="${title}"]`);

  // Check if any other checkboxes with the same title are selected
  const isOtherCheckboxSelected = selectedCheckboxes.some(
    (checkboxId) => checkboxId !== id && checkboxId.includes(title)
  );

  // If other checkboxes with the same title are selected, prompt the user for confirmation
  if (!isOtherCheckboxSelected) {
    if(isChecked){
      var confirmed = window.confirm(`Do you want to assign all programs?`);
    }else{
      var confirmed = window.confirm(`Do you want to unassign all programs?`);
    }
 

    // If the user confirms, update the state accordingly
    if (confirmed) {
      const checkboxesToUpdate = checkboxesWithSameTitle.toArray().map((checkbox) => {
        return checkbox.value;
      });
      console.log(checkboxesToUpdate,'get selected');
      // Update selectedCheckboxes based on the checkbox change
      if (isChecked) {
        setSelectedCheckboxes([...selectedCheckboxes, ...checkboxesToUpdate]);
        var option = {
                // category: location.state.vocabulary.id,
                TestId:record_id,
                Type: checkboxesToUpdate,
                qId: idParam[0],
               }
      } else {
        const updatedCheckboxes = selectedCheckboxes.filter(
          (checkboxId) => !checkboxesToUpdate.includes(checkboxId)
        );
        var option = {
          // category: location.state.vocabulary.id,
          TestId:record_id,
          Type: checkboxesToUpdate,
          qId: idParam[0],
          delete:true,
         }
        setSelectedCheckboxes(updatedCheckboxes);
      }
    } else {
      // If the user cancels, revert the checkbox state
      e.target.checked = isChecked;
      var option = {
        // category: location.state.vocabulary.id,
        TestId:record_id,
        Type: typeText,
        qId: idParam[0],
       }
    }
  } else {
    // If only one checkbox with the same title, update the state accordingly
    if (isChecked) {
      setSelectedCheckboxes([...selectedCheckboxes, id]);
      var option = {
        // category: location.state.vocabulary.id,
        TestId:record_id,
        Type: typeText,
        qId: idParam[0],
       }
    } else {
      const updatedCheckboxes = selectedCheckboxes.filter((checkboxId) => checkboxId !== id);
      setSelectedCheckboxes(updatedCheckboxes);
      var option = {
                  // category: location.state.vocabulary.id,
                  TestId:record_id,
                  Type: typeText,
                  qId: idParam[0],
                  delete:true,
                 }
    }
  }

   const resultRes = await axios.post(
    "https://api.webtracktechnologies.com.au:8002/studytest/assign/program",
    option,
    {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        // Add other headers if needed
      },
    }
  );
  if(resultRes){
  
    alert(resultRes.data.message);
    //window.location.href = '/classroom/study-test';
  }

};
const generateAlphabet = () => {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXY';
  const result = [];

  for (let i = 0; i < alphabet.length; i++) {
    const letter = alphabet[i];
    result.push(letter);

    for (let j = 0; j < 7; j++) {
      result.push(`${letter}${count + j}`);
    }
  }

  return result;
};

const getScoreForPrograms = (id,start) => {
  if(files && files.data && files.data["Vocabulary Quiz"]){
    files.data["Vocabulary Quiz"].forEach(program => {console.log(program.program_id,id,program.vocab_q_start,start)
    // Check if program_id is equal to 10 and vocab_q_start is equal to 1
    if (program.program_id === id && program.vocab_q_start === start) {
      // Print the total_score of the matching program
      console.log(program.total_score,'get score');
      return program.total_score;

    }
  })
}
};
const compareLevels = (a, b) => {
  // Check if either of the levels is 'P' or 'K'
  if (a.name === 'P') {
      return -1; // Move 'P' to the beginning
  } else if (b.name === 'P') {
      return 1; // Move 'P' to the beginning
  } else if (a.name === 'K') {
      return a.name === 'P' ? 1 : -1; // Move 'K' to the beginning after 'P'
  } else if (b.name === 'K') {
      return b.name === 'P' ? -1 : 1; // Move 'K' to the beginning after 'P'
  } else {
      // Otherwise, maintain the original order
      return a.id - b.id;
  }
};

useEffect(() => {


  if((passage && passage.data && passage.data.assignedprograms)){
  // Add blink_text class to the corresponding sidebar div
  passage.data.assignedprograms.forEach(level => {
    //alert(level);
    if(level !== 'Level P'){
    // Extract the numeric part from the level string
    const levelNumber = parseInt(level.match(/\d+/)[0]);
console.log(levelNumber,'get level');
    // Find the corresponding sidebar div by matching the level number with the div number
    const sidebarDiv = document.querySelector(`.sidebar-fulldiv-${levelNumber}`);
    if (sidebarDiv) {
      sidebarDiv.classList.add('blink_text');
    }
    }

  });
  }
  if((passage && passage.data && passage.data.homework_levels)){
    // Add blink_text class to the corresponding sidebar div
    passage.data.homework_levels.forEach(level => {
      //alert(level);
      if(level !== 'Level P'){
      // Extract the numeric part from the level string
      const levelNumber = parseInt(level.match(/\d+/)[0]);
  console.log(levelNumber,'get level');
      // Find the corresponding sidebar div by matching the level number with the div number
      const sidebarDiv = document.querySelector(`.sidebar-fulldiv-${levelNumber}`);
      if (sidebarDiv) {
        sidebarDiv.classList.add('blink_text');
      }
      }
  
    });
    }
  //if(passage && passage.data && passage.data.levels){
    var levelsVar = [
      {
          "name": "Level 1",
          "id": 1
      },
      {
          "name": "Level 2",
          "id": 2
      },
      {
          "name": "Level 3",
          "id": 3
      },
      {
          "name": "Level 4",
          "id": 4
      },
      {
        "name": "Level 5",
        "id": 5
    },
      {
          "name": "Level 6",
          "id": 6
      },
      {
          "name": "Level 7",
          "id": 8
      },
      {
          "name": "Level 8",
          "id": 9
      },
      {
          "name": "Level 9",
          "id": 10
      },
      {
          "name": "Level 10",
          "id": 11
      },
      {
          "name": "Level P",
          "id": 12
      },
      {
          "name": "Level K",
          "id": 13
      },
      {
          "name": "Level 11",
          "id": 14
      },
      {
          "name": "Level 12",
          "id": 15
      },
      {
          "name": "Level 13",
          "id": 16
      }
  ];
    // Add blink_text class to the corresponding sidebar div
    const modifiedLevels = levelsVar.map(level => ({
      ...level,
      name: level.name.replace('Level ', '') // Remove "Level" from the name
  }));

    const sortedLevels = [...modifiedLevels].sort(compareLevels);
    setSortedLevels(sortedLevels);
    //}
    // if(passage && passage.data && passage.data.levels){
    //   // Add blink_text class to the corresponding sidebar div
    //   const modifiedLevels = passage.data.levels.map(level => ({
    //     ...level,
    //     name: level.name.replace('Level ', '') // Remove "Level" from the name
    // }));
 
    //   const sortedLevels = [...modifiedLevels].sort(compareLevels);
    //   setSortedLevels(sortedLevels);
    //   }

}, [passage]);
const generateAlphabetForIndex = (index) => {console.log(index,'get index for alphabet');
  if (index === 0) {
    return 'A';
  }
  
  const alphabetIndex = Math.floor(index / 8); // Reset alphabet every 7 items
  const alphabet = String.fromCharCode(65 + alphabetIndex); // ASCII code for 'A'

  const itemIndex = index % 8; // Index within the current alphabet, starting from 0
  if (itemIndex === 0) {
    if (index === 7) { // If index is 7, print A7
      return 'A7';
    }
    return String.fromCharCode(65 + alphabetIndex); // Print the next alphabet
  } else {
    return `${alphabet}${itemIndex}`;
  }
};


const highlightedIds = [];
// const findHighlightedIds = () => {
//   const highlightedIds = []; // Initialize an array to store IDs
//   const listItems = document.querySelectorAll('.content-div li');
  
//   listItems.forEach((item) => {
//     if (item.classList.contains('highlight_li_text')) {
//       const h4Element = item.querySelector('h4'); // Find the h4 element
//       console.log(h4Element,'h4Element');
//       if (h4Element) { // Ensure h4 exists
//         highlightedIds.push(h4Element.id); // Add its ID to the array
//       }
//     }
//   });

//   console.log(highlightedIds);
//   return highlightedIds;
// };

const findHighlightedIds = () => {
  const highlightedIds = []; // Initialize an array to store IDs
  const listItems = document.querySelectorAll('.content-div li.highlight_li_text');

  listItems.forEach((item) => {
    const h4Element = item.closest('ul').querySelector('h4'); // Find the h4 in the parent ul
    console.log(h4Element.id,'h4Element');
    if (h4Element) {
      highlightedIds.push(h4Element.id); // Add its ID to the array
    }
  });


  return highlightedIds;
};


console.log(highlightedIds,'get ids');
let lastIndex = '';
  // Check if an item should be highlighted
  const isHighlighted = (item) => {
    var highlightedIds = findHighlightedIds();
    console.log(highlightedIds,'highlightedIds');
    return highlightedIds.includes(item);
  };
  const openSlide = () =>{
    setOpenSubMenu(true);
  }
  const findMatchingQuestion = (response, chunk_id, type) => {
    return response.find(item => item.category === type && item.chunk_id === chunk_id);
};

const findVQuizScore = (type, questionId) => {

 // if(type === 'VLCH'){
    if(results && results.data){
      const vQuiz = (results) ? results.data[type] : '';
      console.log(vQuiz,'get VLCH');
      const matchedItem = vQuiz.find(item => item.chunk_id === questionId);
      return matchedItem ? ((matchedItem.total_score > 100) ? 100 : matchedItem.total_score) : null; // Return total_score or null if not found
    }
 // }
  
  
};
    return (

      <section className="custom-sign-assign-question">
      <div className="container">
  <div className="row">

    <div className="level-box">
      <div className="leven-box-sidebar">
      {(sortedLevels) ? sortedLevels.map((level, index) => (
        <div className={`sidebar-fulldiv-${level.name}`}>
          <div className="sidebar-box">
            <a href={`/study/${level.name}`}>
              <span className="level">{level.name}</span>
              <span className="leven-name">LEVEL ONE</span>
            </a>
          </div>
        </div>
      )) : ''}
      </div>
      <div className="leven-box-content">
        <h2>LEVEL {numberToWord(level)} ENGLISH</h2>
        <p>
          LIKE Study offers more than 100 LEVEL {numberToWord(level)} language arts skills to
          explore and learn! Not sure where to start? Go to your personalized
          Recommendations wall and choose a skill that looks interesting—they’re
          all picked just for you!
        </p>
        <div className="nav-q">
          <a href="#vocabulary" onClick={openSlide}>Vocabulary</a>
          <a href="#speaking">Speaking</a>
          <a href="#writing">Writing</a>
          <a href="#grammar">Grammar</a>
          <a href="#reading">Reading</a>
          <a href="#listening">Listening</a>
        </div>
        {(openSubMenu) ?
        <div className='open-submenu-vocab'>
        <ul>
          <li><a href="#vocabulary-quiz">Vocabulary Quiz: Multiple choice</a></li>
          <li><a href="#vocabulary-listen-choose">Vocabulary: Listen and choose what you hear</a></li>
          <li><a href="#vocabulary-listen-choose-correct-meaning">Vocabulary: Listen and choose the correct meaning</a></li>
          <li><a href="#vocabulary-read-out-loud">Vocabulary: Read out loud</a></li>
          <li><a href="#vocabulary-writing-eng-kor">Vocabulary Writing: English word to Korean meaning</a></li>
          <li><a href="#vocabulary-writing-kor-eng">Vocabulary Writing: Korean meaning to English word</a></li>
          <li><a href="#vocabulary-writing-eng-eng1">Vocabulary Writing: English word to English meaning</a></li>
          <li><a href="#vocabulary-writing-eng-eng2">Vocabulary Writing: English meaning to English word</a></li>
          <li><a href="#vocabulary-listen-writing-eng-kor">Vocabulary Listening Writing: Listen and write what you hear (English word to Korean meaning)</a></li>
          <li><a href="#vocabulary-listen-writing-kor-eng">Vocabulary Listening Writing: Listen and write the correct meaning (Korean meaning to English word)</a></li>
          <li><a href="#vocabulary-listen-write">Vocabulary Listening Writing: Listen and write what you hear (English word to English meaning)</a></li>
          <li><a href="#vocabulary-listen-write-correct-meaning">Vocabulary Listening Writing: Listen and write the correct meaning (English word to English meaning)</a></li>
        </ul>
        </div> : ''
        }
       
        <div className="nav-q" style={{overflow:'auto'}}>
        {generateAlphabet().map((item, index) => (
          <a href={'#'+item} className={(isHighlighted(item)) ? 'tag_highlight' : ''}>{item}</a>
        ))}
        </div>
        
        <div className="content-div" id="vocabulary">
          <h4>Vocabulary</h4>
          <div className='vocabulary-quiz' id="vocabulary-quiz">
          <h6 >Vocabulary Quiz: Multiple Choice</h6>
          
          
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
      lastIndex = index;
  
      return (
        <ul key={index}>
           <h4 id={generateAlphabetForIndex(index)}>
                    <span className="ordering">{generateAlphabetForIndex(index)}. </span>
                    {dictate.vocabName}
                  </h4>
          {/* <h4 id={lastIndex}><span className="ordering">{(index === 0) ? 'A' : `A${index}`}. </span> {dictate.vocabName} </h4> */}
          {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            <li key={index1} className={findMatchingQuestion(dictate.Vocab_homework , question.id, 'VQuiz') ? 'highlight_li_text' : ''}>
              {index1+1}.<a href={`/vocabulary-question/${dictate.vocabid || question.vocab_id}/${question.question_start}/${question.id}`}>{question.title}
              {/* <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span> */}
              <span className='numberOf'>{findVQuizScore('VQuiz',question.id) ? ' ('+findVQuizScore('VQuiz',question.id)+') ' : ''}</span> 

            </a>
            </li>
          )) : <h5>No Records Found.</h5>}
        </ul>
      );
    }): <h5>No Records Found.</h5>}
         
          </div>
          <div className='vocabulary-listen-choose' id="vocabulary-listen-choose">
          <h6>Vocabulary: Listen and choose what you hear</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {

        lastIndex = lastIndex + 1;
     // console.log(lastIndex,'get last');
      return (
        <ul key={index}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
          {/* <h4 id={lastIndex}><span className="ordering">{(lastIndex === 0) ? 'A' : `A${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
          {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            <li key={index1} className={findMatchingQuestion(dictate.Vocab_homework , question.id, 'VLCH') ? 'highlight_li_text' : ''}>
              {index1+1}.<a href={`/vocabulary-listening-question/LCH/${dictate.vocabid || question.vocab_id}/${question.question_start}/${question.id}`}>{question.title}
              {/* <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span> */}
              <span className='numberOf'>{findVQuizScore('VLCH',question.id) ? ' ('+findVQuizScore('VLCH',question.id)+') ' : ''}</span> 

              </a>
            </li>
          )) : <h5>No Records Found.</h5>}
        </ul>
      );
    }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-choose-correct-meaning' id="vocabulary-listen-choose-correct-meaning">
          <h6>Vocabulary: Listen and choose the correct meaning</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] &&  passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // if(lastIndex == 7){
            //   lastIndex = index;
            // }else{
            //   lastIndex = index;
            // }
            console.log(lastIndex,'get last');
            return (
          <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
            {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(index === 0) ? 'B' : `B${index}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
              
           <li className={findMatchingQuestion(dictate.Vocab_homework , question.id, 'VLCM') ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/vocabulary-listening-question/LCM/${dictate.vocabid || question.vocab_id}/${question.question_start}/${question.id}`}>{question.title}
           {/* <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span> */}
           <span className='numberOf'>{findVQuizScore('VLCM',question.id) ? ' ('+findVQuizScore('VLCM',question.id)+') ' : ''}</span> 

           </a>
       </li>
             )) : <h5>No Records Found.</h5>}
             </ul>
           );
         }): <h5>No Records Found.</h5>}
         
          </div>
          <div className='vocabulary-read-out-loud' id="vocabulary-read-out-loud">
          <h6>Vocabulary: Read out loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(findMatchingQuestion(dictate.Vocab_homework , question.id, 'VROL')) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/vocabulary-listening-read/${dictate.vocabid || question.vocab_id}/${question.question_start}/VROL/${question.id}` }>{question.title}
           {/* <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span> */}
           <span className='numberOf'>{findVQuizScore('VROL',question.id) ? ' ('+findVQuizScore('VROL',question.id)+') ' : ''}</span> 

           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-writing-eng-kor' id="vocabulary-writing-eng-kor">
          <h6>Vocabulary Writing: English word to Korean meaning</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2Korean/${dictate.vocabid || question.vocab_id}/Writing/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-writing-kor-eng' id="vocabulary-writing-kor-eng">
          <h6>Vocabulary Writing: Korean meaning to English word</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingKorean2English/${dictate.vocabid || question.vocab_id}/Writing/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-writing-eng-eng1' id="vocabulary-writing-eng-eng1">
          <h6>Vocabulary Writing: English word to English meaning</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2English/${dictate.vocabid || question.vocab_id}/Eng1/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-writing-eng-eng2' id="vocabulary-writing-eng-eng2">
          <h6>Vocabulary Writing: English meaning to English word</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2English/${dictate.vocabid || question.vocab_id}/Eng2/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-writing-eng-kor' id="vocabulary-listen-writing-eng-kor">
          <h6>Vocabulary Listening Writing: Listen and write what you hear (English word to Korean meaning)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2Korean/${dictate.vocabid || question.vocab_id}/Listening-Eng2Kor/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-writing-eng-eng2' id="vocabulary-listen-writing-eng-eng2">
          <h6>Vocabulary Listening Writing: Listen and write the correct meaning (Korean meaning to English word)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingKorean2English/${dictate.vocabid || question.vocab_id}/KorM2EngW/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-writing-eng-eng1' id="vocabulary-listen-writing-eng-eng1">
          <h6>Vocabulary Listening Writing: Listen and write what you hear (English word to English meaning)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2English/${dictate.vocabid || question.vocab_id}/Listening-EngW2EngM/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='vocabulary-listen-writing-kor-eng' id="vocabulary-listen-writing-kor-eng">
          <h6>Vocabulary Listening Writing: Listen and write the correct meaning (English meaning to English word)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Vocabulary"] && passage.data.programs["Vocabulary"]["VocabQuiz"]) ? passage.data.programs["Vocabulary"]["VocabQuiz"].map((dictate, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
           <ul>
             <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {dictate.vocabName}
                  </h4>
             {/* <h4 id={`A${String.fromCharCode(65 + Math.floor(index / 7))}${index % 7 + 1}`}><span className="ordering">{(lastIndex === 0) ? 'B' : `B${lastIndex}`}. </span> {dictate.vocabName} </h4> */}
            {(dictate && dictate.questions_chunks) ? dictate.questions_chunks.map((question, index1) => (
            
           <li className={(question.homeworkassigned == 1) ? 'highlight_li_text' : ''}>
           {index1+1}.<a href={`/study/VocabularyWritingEnglish2English/${dictate.vocabid || question.vocab_id}/Listening-EngM2EngW/${question.question_start}/${question.id}`}>{question.title}
           <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span>
           </a>
       </li>
            )) : <h5>No Records Found.</h5>}
            </ul>  );
         }): <h5>No Records Found.</h5>}
          
          </div>
          
          
          
        </div>
        <div className="content-div" id="speaking">
          <h4>Speaking</h4>
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Sentence Read out loud"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Sentence Read-Out-Loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Sentence Read out loud"]) ? passage.data.programs["Speaking"]["Sentence Read out loud"].map((speakout, index) => {
            lastIndex = lastIndex + 1;
            // console.log(lastIndex,'get last');
             return (
            
            <ul>
            
           <li className={(speakout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
             
                  </h4><a href={`/study/speaking-sentence-read/${speakout.id}/${level}/SROL`}>{speakout.categorytitle}<span className='numberOf'>{(speakout.total_score || speakout.total_score == 0) ? ' ('+speakout.total_score+') ':''}</span></a>
       </li>
             
            </ul>);
         }): <h5>No Records Found.</h5>}
          
          </div>
          </>:''}
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking Respond In English"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Speaking: Respond in English</h6>
          
         
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking Respond In English"]) ? passage.data.programs["Speaking"]["Speaking Respond In English"].map((speakout, index) => {
             lastIndex = lastIndex + 1;
             // console.log(lastIndex,'get last');
              return (
            <ul>
            
           <li className={(speakout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
             </h4><a href={`/study/speaking-sentence-read/${speakout.id}/${level}/SRROL`}>{speakout.categorytitle} <span className='numberOf'>{(speakout.total_score || speakout.total_score == 0) ? ' ('+speakout.total_score+') ':''}</span></a>
       </li>
             
            </ul>);
         }): <h5>No Records Found.</h5>}
          
          </div>
          </>:''}
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Independent Speaking"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Independent Speaking</h6>
          
         
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Independent Speaking"]) ? passage.data.programs["Speaking"]["Independent Speaking"].map((speakout, index) => {
           lastIndex = lastIndex + 1;
           // console.log(lastIndex,'get last');
            return (  
             <ul>
            
           <li className={(speakout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
             
                  </h4><a href={`/study/read-out-loud/${speakout.id}/ROL`}>{speakout.categorytitle}</a>
       </li>
             
            </ul>);
         }): <h5>No Records Found.</h5>}
          
          </div>
          </>:''}
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking: Sentence Read out loud"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Speaking: Sentence Read out loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking: Sentence Read out loud"]) ? passage.data.programs["Speaking"]["Speaking: Sentence Read out loud"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
            <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">{(index === 0) ? 'C' : `C${index}`}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study/speaking-sentence-translation/${grammar.id}/Speaking/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul>  ); }): <h5>No Records Found.</h5>}
            
          
          </div>
          </>:''}
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking: Sentence Translation"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Speaking: Sentence Translation</h6>
          
         
          {(passage && passage.data && passage.data.programs["Speaking"] && passage.data.programs["Speaking"]["Speaking: Sentence Translation"]) ? passage.data.programs["Speaking"]["Speaking: Sentence Translation"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
           <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study/speaking-sentence-translation/${grammar.id}/Grammar/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul>); }): <h5>No Records Found.</h5>}
            
          
          </div>
          </>:''}
        </div>
        <div className="content-div" id="writing">
          <h4>Writing</h4>
          {(passage && passage.data && passage.data.programs["Writing"] && passage.data.programs["Writing"]["Sentence Writing: English"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Sentence Writing: English</h6>
          
         
          {(passage && passage.data && passage.data.programs["Writing"] && passage.data.programs["Writing"]["Sentence Writing: English"]) ? passage.data.programs["Writing"]["Sentence Writing: English"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
            <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study/WritingTranslationKorean2English/${grammar.id}/Writing/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul>); }): <h5>No Records Found.</h5>}
            
          
          </div>
          </>:''}

          {(passage && passage.data && passage.data.programs["Writing"] && passage.data.programs["Writing"]["Sentence Writing: Korean"]) ? 
          <>
          <div className='speaking-read-out-loud'>
          <h6>Sentence Writing: Korean</h6>
          
         
          {(passage && passage.data && passage.data.programs["Writing"] && passage.data.programs["Writing"]["Sentence Writing: Korean"]) ? passage.data.programs["Writing"]["Sentence Writing: Korean"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
              <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study/WritingTranslationEnglish2Korean/${grammar.id}/Writing/${question.question_start}`}>{question.title} <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
            
          
          </div>
          </>:''}

        </div>
        <div className="content-div" id="grammar">
          <h4>Grammar</h4>
          <div className='reading-read-out-loud'>
          <h6>Grammar: Sentence Unscramble (English)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Grammer"] && passage.data.programs["Grammer"]["Grammar: Sentence Unscramble (English)"]) ? passage.data.programs["Grammer"]["Grammar: Sentence Unscramble (English)"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
            <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study-sentence-unscramble/${grammar.id}/Grammar/${question.question_start}`}>{question.title} <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
            
          
          </div>

          <div className='grammar-fill-in-the-blanks'>
          <h6>Grammar: Fill in the Blanks (English)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Grammer"] && passage.data.programs["Grammer"]["Grammar: Fill in the Blanks (English)"]) ? passage.data.programs["Grammer"]["Grammar: Fill in the Blanks (English)"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             
             <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.assigned) ? 'highlight_li_text' : ''}>
           {index+1}.<a href={`/study-fill-in-the-blanks/${grammar.id}/english/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
           </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
          
          </div>

          <div className='grammar-fill-in-the-blanks'>
          <h6>Grammar: Fill in the Blanks (Korean)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Grammer"] && passage.data.programs["Grammer"]["Grammar: Fill in the Blanks (Korean)"]) ? passage.data.programs["Grammer"]["Grammar: Fill in the Blanks (Korean)"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.assigned) ? 'highlight_li_text' : ''}>
           {index+1}.<a href={`/study-fill-in-the-blanks/${grammar.id}/korean/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
           </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
          
          </div>

          <div className='grammar-fill-in-the-blanks'>
          <h6>Grammar: Type in the Answers (English)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Grammer"] && passage.data.programs["Grammer"]["Grammar: Type in the Answers (English)"]) ? passage.data.programs["Grammer"]["Grammar: Type in the Answers (English)"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.assigned) ? 'highlight_li_text' : ''}>
           {index+1}.<a href={`/study-fill-in-the-blanks-write/${grammar.id}/english/${question.question_start}`}>{question.title}<span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
           </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
          
          </div>

          <div className='grammar-fill-in-the-blanks'>
          <h6>Grammar: Type in the Answers (Korean)</h6>
          
         
          {(passage && passage.data && passage.data.programs["Grammer"] && passage.data.programs["Grammer"]["Grammar: Type in the Answers (Korean)"]) ? passage.data.programs["Grammer"]["Grammar: Type in the Answers (Korean)"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.assigned) ? 'highlight_li_text' : ''}>
           {index+1}.<a href={`/study-fill-in-the-blanks-write/${grammar.id}/korean/${question.question_start}`}>{question.title} <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
           </li>
             )) : <h5>No Records Found.</h5>}
             </ul> ); }): <h5>No Records Found.</h5>}
          
          </div>
          
        </div>
        <div className="content-div" id="reading">
          <h4>Reading</h4>
          <div className='reading-read-out-loud'>
          <h6>Reading: Text Read out Loud</h6>
          
         
          {(passage && passage.data && passage.data.programs["Reading"] && passage.data.programs["Reading"]["Read out Loud"]) ? passage.data.programs["Reading"]["Read out Loud"].map((readout, index) => {
              lastIndex = lastIndex + 1;
    
               
               return (
             <ul>
            
           <li className={(readout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                   
                  </h4><a href={`/study/read-out-loud/${readout.id}/ROL`}>{readout.categorytitle} <span className='numberOf'>{(readout.total_score || readout.total_score == 0) ? ' ('+readout.total_score+') ':''}</span> </a>
       </li>
             
            </ul>); }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='reading-multiple-choice'>
          <h6>Reading: Multiple Choice Quiz</h6>
          
         
          {(passage && passage.data && passage.data.programs["Reading"] && passage.data.programs["Reading"]["Reading Multiple Choice"]) ? passage.data.programs["Reading"]["Reading Multiple Choice"].map((readout, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
            <ul>
            
           <li className={(readout.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                   
                  </h4><a href={`/study/reading-multiple-choice/${readout.id}/RMC`}>{readout.categorytitle} <span className='numberOf'>{(readout.total_score || readout.total_score == 0) ? ' ('+readout.total_score+') ':''}</span></a>
       </li>
             
            </ul>); }): <h5>No Records Found.</h5>}
          
          </div>
        </div>
        <div className="content-div" id="listening">
          <h4>Listening</h4>
          <div className='listening-multiple-choice'>
          <h6>Listening</h6>
          
         
          {(passage && passage.data && passage.data.programs["Listening"] && passage.data.programs["Listening"]["Listening Multiple Choice"]) ? passage.data.programs["Listening"]["Listening Multiple Choice"].map((listen, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             <ul>
            
           <li className={(listen.homeworkassigned) ? 'highlight_li_text' : ''}>
           <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                   
                  </h4><a href={`/study/listening-multiple-choice/${listen.id}/LMC`}>{listen.categorytitle} <span className='numberOf'>{(listen.total_score || listen.total_score == 0) ? ' ('+listen.total_score+') ':''}</span></a>
       </li>
             
            </ul>); }): <h5>No Records Found.</h5>}
          
          </div>
          <div className='listening-multiple-choice'>
          <h6>Listening: Sentence Unscramble</h6>
          
         
          {(passage && passage.data && passage.data.programs["Listening"] && passage.data.programs["Listening"]["Listening: Sentence Unscramble"]) ? passage.data.programs["Listening"]["Listening: Sentence Unscramble"].map((grammar, index) => {
            lastIndex = lastIndex + 1;
  
             
             return (
             <ul>
               <h4 id={generateAlphabetForIndex(lastIndex)}>
                    <span className="ordering">{generateAlphabetForIndex(lastIndex)}. </span>
                    {grammar.categorytitle}
                  </h4>
             {/* <h4 id={(index != 0) ? 'A'+index :'A'}><span class="ordering">A{(index != 0) ? index :''}. </span> {grammar.categorytitle} </h4> */}
             {(grammar && grammar.questions_chunks) ? grammar.questions_chunks.map((question, index1) => (
             
            <li className={(question.homeworkassigned) ? 'highlight_li_text' : ''}>
            {index1+1}.<a href={`/study-sentence-unscramble/${grammar.id}/Listening/${question.question_start}`}>{question.title}  <span className='numberOf'>{(question.total_score || question.total_score == 0) ? ' ('+question.total_score+') ':''}</span></a>
        </li>
             )) : <h5>No Records Found.</h5>}
             </ul>); }): <h5>No Records Found.</h5>}
            
          
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    </section>
      
    )
}
